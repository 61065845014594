import { BaseService } from "./BaseService";
const TipoDocumentoService = {
	async listar(empresaId) {
		const respuesta = await BaseService.get("/tipoDocumento/listarPorEmpresaYResponsableCargaDocumento", {
			params: {
				empresaId: empresaId,
			},
		});
		return respuesta.data;
	},
	async listarDocumentosPersonales(empresaId, categoriaDocumentoId) {
		const respuesta = await BaseService.get("/tipoDocumento", {
			params: {
				empresaId: empresaId,
				categoriaDocumentoId: categoriaDocumentoId,
			},
		});
		return respuesta.data;
	},
	async listarPorUsuario(login) {
		const respuesta = await BaseService.get("/tipoDocumento/usuario", {
		params: {
			login: login,
		},
		});
		return respuesta.data;
	},
	async listarPorColaborador(login) {
		const respuesta = await BaseService.get("/tipoDocumento/colaborador", {
		params: {
			login: login,
		},
		});
		return respuesta.data;
  	},
};
export default TipoDocumentoService;
