import React, { useContext, useEffect, useState } from "react";
import { Container } from "components/Container";
import { Button, notification, Space } from "antd";
import { UnorderedListOutlined } from "@ant-design/icons";
import { useAtom } from "jotai";
import { useHistory } from "react-router-dom";
import {
  visibleModalFiltrarAtom,
  documentosAtom,
  filtrosSeleccionadosAtom,
  tagVisibleAtom,
  carpetaAtom,
  filtrosAtom,
  categoriasAtom,
  opcionesAtom,
  opcionesSeleccionadasAtom,
  valoresInicialesAtom,
  codigosOpcionesAtom,
  listaTiposDocumentoFiltroAtom,
  listraCarpetasFiltroAtom,
  loadingAtom,

} from "./ConsultaDocumentoStore";
import { ModalFiltrar, TagFiltros, TablaDocumentos } from "./componentes";
import { SecurityContext } from "context/SecurityContextProvider";
//import CarpetaService from "services/CarpetaService";
import DetalleEnvioService from "services/DetalleEnvioService";
import { saveAs } from "file-saver";
import { ModalHistorialNotificacion } from "./componentes/ModalHistorialNotificacion";
import { ModalActualizarCorreo } from "./componentes/ModalActualizarCorreo";
import { ModalVisorPdf } from "components/Modal/ModalVisorPdf";
import { ModalAsignarCarpeta } from "./componentes/ModalAsignarCarpeta";
import { CampoColaborador } from "enum/CampoColaborador";
import { OpcionService } from "services";
//import SedeService from "services/SedeService";
// import SubsedeService from "services/SubsedeService";
import SeccionService from "services/SeccionService";
import CargoService from "services/CargoService";
// import TipoPlanillaService from "services/TipoPlanillaService";
//import PerfilService from "services/PerfilService";
import TipoDocumentoService from "services/TipoDocumentoService"
// import UsuarioService from "services/UsuarioService";
import { ModalSubirDocumento } from "./componentes/ModalSubirDocumento";

const ConsultaDocumentoPage = () => {
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const camposColaboradores = getUsuarioAutenticado().empresaSeleccionada.camposColaborador;

  const [, setVisibleModalFiltrar] = useAtom(visibleModalFiltrarAtom);

  const [, setTagVisible] = useAtom(tagVisibleAtom);
  const [, setListaCarpetasFiltro] = useAtom(listraCarpetasFiltroAtom);
  const [documentos, setDocumentos] = useAtom(documentosAtom);
  const [filtrosSeleccionados, setFiltrosSeleccionados] = useAtom(filtrosSeleccionadosAtom);
  const [, setCarpetas] = useAtom(carpetaAtom);
  const [, setTipoDocumento] = useAtom(listaTiposDocumentoFiltroAtom);
  const [, setCategorias] = useAtom(categoriasAtom);
  const [, setOpciones] = useAtom(opcionesAtom);
  const [, setOpcionesSeleccionadas] = useAtom(opcionesSeleccionadasAtom);
  const [, setValoresIniciales] = useAtom(valoresInicialesAtom);

  //const [datosPaginacion,setDatosPaginacion] = useAtom(datosPaginacionAtom);
  const [filtros, setFiltros] = useAtom(filtrosAtom);
  const [, setCodigosOpciones] = useAtom(codigosOpcionesAtom);
  const [, setLoading] = useAtom(loadingAtom);
  const [loadingReporteExcel, setLoadingReporteExcel] = useState(false);
  const [loadingReportePdf, setLoadingReportePdf] = useState(false);
  const history = useHistory();

  useEffect(() => {

    const cargarDatosIniciales = async () => {

      const filtrosFormInicial = {
        cargo: null,
        carpeta: null,
        codigoColaborador: null,
        colaborador: null,
        documentoIdentidad: null,
        estadoDocumento: null,
        fechaEnvio: null,
        filtroCargo: undefined,
        filtroPerfil: undefined,
        filtroSeccion: undefined,
        filtroSede: undefined,
        filtroSubSede: undefined,
        filtroTipoPlanilla: undefined,
        perfil: null,
        periodoDocumento: null,
        seccion: null,
        sede: null,
        subSede: null,
        tipoDocumento: undefined,
        tipoPlanilla: null,
        fechaIngreso: null,
        fechaFirma: null
      }
      setFiltros([]);
      setFiltrosSeleccionados(filtrosFormInicial);


      // if (visibleModal) {
      // const usuario = await UsuarioService.buscarPorOrganizacionLogin(
      //   getUsuarioAutenticado().organizacionId,
      //   getUsuarioAutenticado().login
      // );

      //setFiltrosSeleccionados(undefined);

      // const empresaSeleccionada = usuario?.empresas.find((empresa) => empresa.id === getUsuarioAutenticado().empresaSeleccionada.id);
      // camposColaboradores.forEach((campo) => {
      //   empresaSeleccionada[campo.nombre] = undefined;
      // });

      var _categorias = [];

      const _tipoDocumento = await TipoDocumentoService.listarPorColaborador(getUsuarioAutenticado().numeroDocumentoIdentidad);
      setTipoDocumento(_tipoDocumento);

      // const _carpetas = await getListaCarpetasFiltro(empresaSeleccionada);
      // setListaCarpetasFiltro(_carpetas);

      // var perfiles = (await PerfilService.listarPorUsuario(getUsuarioAutenticado().login)).map((perfil) =>
      //   getEntidad(perfil, CampoColaborador.PERFIL)
      // );
      // _categorias.push(...perfiles);

      // var sedes = (await SedeService.listarPorUsuario(getUsuarioAutenticado().login)).map((sede) =>
      //   getEntidad(sede, CampoColaborador.SEDE)
      // );
      // _categorias.push(...sedes);

      // var subsedes = (await SubsedeService.listarPorUsuario(getUsuarioAutenticado().login)).map((subsede) =>
      //   getEntidad(subsede, CampoColaborador.SUBSEDE)
      // );
      // _categorias.push(...subsedes);

      var secciones = (await SeccionService.listarPorColaborador(getUsuarioAutenticado().numeroDocumentoIdentidad)).map((seccion) =>
        getEntidad(seccion, CampoColaborador.SECCION)
      );
      _categorias.push(...secciones);

      var cargos = (await CargoService.listarPorColaborador(getUsuarioAutenticado().numeroDocumentoIdentidad)).map((cargo) =>
        getEntidad(cargo, CampoColaborador.CARGO)
      );
      _categorias.push(...cargos);

      // var planillas = (await TipoPlanillaService.listarPorUsuario(getUsuarioAutenticado().login)).map((planilla) =>
      //   getEntidad(planilla, CampoColaborador.PLANILLA)
      // );
      // _categorias.push(...planillas);

      //console.log("_categorias", _categorias);
      setCategorias(_categorias);

      var _opciones = [];
      camposColaboradores.forEach((campo) => {
        if (campo.campoDependencia === null) {
          _opciones.push({
            campo: campo.campo,
            datos: _categorias
              .filter((categoria) => categoria.campo === campo.campo)
              .map((categoria) => {
                var opcion = {
                  id: categoria.id,
                  label: categoria.nombre,
                  value: categoria.id,
                  campo: campo.campo,
                  campodependencia: null,
                  iddependencia: null,
                };
                return opcion;
              }),
          });
        } else {
          _opciones.push({ campo: campo.campo, datos: [] });
        }
      });
      // console.log("_opciones", _opciones);
      setOpciones(_opciones);

      //  console.log("empresaSeleccionada", empresaSeleccionada);

      var _opcionesSeleccionadas = camposColaboradores.map((campo) => {
        const datos = filtros[campo.nombre];

        return {
          campo: campo.campo,
          datos: datos !== undefined && datos !== null ? datos : [],
        };
      });
      //  console.log("_opcionesSeleccionadas", _opcionesSeleccionadas);
      setOpcionesSeleccionadas(_opcionesSeleccionadas);

      var _opcionesIniciales = [];
      camposColaboradores.forEach((campo) => {
        const categoriasSeleccionadasPorCampo = _opcionesSeleccionadas.find((c) => c.campo === campo.campo).datos;
        const categoriasPorCampo = _categorias.filter((c) => c.campo === campo.campo && categoriasSeleccionadasPorCampo.includes(c.id));

        _opcionesIniciales.push({
          campo: campo.campo,
          datos: categoriasPorCampo
            .filter((c) => categoriasSeleccionadasPorCampo.includes(c.id))
            .map((c) => {
              var opcion = {
                id: c.id,
                label: c.nombre,
                value: c.id,
                campo: campo.campo,
                campodependencia: c.campoDependencia,
                iddependencia: c.idDependencia,
              };
              return opcion;
            }),
        });
      });
      setValoresIniciales(_opcionesIniciales);

      const codigosOpciones = await OpcionService.listar(getUsuarioAutenticado().id);
      setCodigosOpciones(codigosOpciones);
    };
    cargarDatosIniciales();
  }, []);

  const cargarDocumentos = async (pagina) => {
    setLoading(true);
    console.log("consulta", getUsuarioAutenticado());
    const respuestaConsultaDocumentos = await DetalleEnvioService.listarConsultaDocumentoPortalColaborador(
      getUsuarioAutenticado().empresaSeleccionada.id,
      getUsuarioAutenticado().numeroDocumentoIdentidad,
      filtrosSeleccionados?.colaboradorDni,
      filtrosSeleccionados?.categoria,
      filtrosSeleccionados?.documentoIdentidad,
      filtrosSeleccionados?.tipoDocumento,
      filtrosSeleccionados?.cargo,
      filtrosSeleccionados?.periodoDocumento === null
        ? null
        : filtrosSeleccionados?.periodoDocumento.format("MM/YYYY"),
      filtrosSeleccionados?.carpeta,
      filtrosSeleccionados?.estadoDocumento,
      filtrosSeleccionados?.sede,
      filtrosSeleccionados?.fechaEnvio === null ? null : filtrosSeleccionados?.fechaEnvio[0]?.format("DD/MM/YYYY"),
      filtrosSeleccionados?.fechaEnvio === null ? null : filtrosSeleccionados?.fechaEnvio[1]?.format("DD/MM/YYYY"),
      pagina != undefined ? pagina : 0,
      30,
      filtrosSeleccionados?.subSede,
      filtrosSeleccionados?.perfil,
      filtrosSeleccionados?.seccion,
      filtrosSeleccionados?.tipoPlanilla,
      filtrosSeleccionados?.codigoColaborador,
      filtrosSeleccionados?.fechaIngreso === null ? null : filtrosSeleccionados?.fechaIngreso[0]?.format("DD/MM/YYYY"),
      filtrosSeleccionados?.fechaIngreso === null ? null : filtrosSeleccionados?.fechaIngreso[1]?.format("DD/MM/YYYY"),
      filtrosSeleccionados?.fechaFirma === null ? null : filtrosSeleccionados?.fechaFirma[0]?.format("DD/MM/YYYY"),
      filtrosSeleccionados?.fechaFirma === null ? null : filtrosSeleccionados?.fechaFirma[1]?.format("DD/MM/YYYY"),
    );

    console.log("respuestaConsultaDocumentos",respuestaConsultaDocumentos);
    setDocumentos({
      ...documentos,
      data: respuestaConsultaDocumentos.detalles,
      paginaActual: respuestaConsultaDocumentos.paginaActual,
      totalDocumentos: respuestaConsultaDocumentos.totalColaboradores,
    });
    setLoading(false)
  };


  useEffect(() => {
    if (filtrosSeleccionados != undefined && Object.entries(filtrosSeleccionados).length > 0) {
      if (
        filtrosSeleccionados?.colaborador != undefined ||
        filtrosSeleccionados?.categoria != undefined ||
        (filtrosSeleccionados?.documentoIdentidad != undefined && filtrosSeleccionados?.documentoIdentidad != "") ||
        (filtrosSeleccionados?.tipoDocumento != undefined && filtrosSeleccionados?.tipoDocumento.length > 0) ||
        (filtrosSeleccionados?.cargo != undefined && filtrosSeleccionados?.cargo.length > 0) ||
        filtrosSeleccionados?.periodoDocumento != undefined ||
        (filtrosSeleccionados?.carpeta != undefined && filtrosSeleccionados?.carpeta.length > 0) ||
        filtrosSeleccionados?.estadoDocumento != undefined ||
        (filtrosSeleccionados?.sede != undefined && filtrosSeleccionados?.sede.length > 0) ||
        filtrosSeleccionados?.fechaEnvio != undefined ||
        (filtrosSeleccionados?.subSede != undefined && filtrosSeleccionados?.subSede.length > 0) ||
        (filtrosSeleccionados?.perfil != undefined && filtrosSeleccionados?.perfil.length > 0) ||
        (filtrosSeleccionados?.seccion != undefined && filtrosSeleccionados?.seccion.length > 0) ||
        (filtrosSeleccionados?.tipoPlanilla != undefined && filtrosSeleccionados?.tipoPlanilla.length > 0) ||
        (filtrosSeleccionados?.codigoColaborador != undefined && filtrosSeleccionados?.codigoColaborador.length > 0)
        || filtrosSeleccionados?.fechaIngreso != undefined
        || filtrosSeleccionados?.fechaFirma != undefined
      ) {
        setTagVisible("block");
      } else {
        setTagVisible("none");
      }
      cargarDocumentos();
    }

  }, [filtrosSeleccionados]);

  function onChangePagination(page) {
    cargarDocumentos(page - 1)
  }



  const getEntidad = (entidad, campo) => {
    const campoDependencia = camposColaboradores.find((c) => c.campo === campo).campoDependencia;
    var campo = { id: entidad.id, nombre: entidad.nombre, campo: campo, campoDependencia: undefined, idDependencia: undefined };
    if (campoDependencia === CampoColaborador.PERFIL) {
      return { ...campo, campoDependencia: CampoColaborador.PERFIL, idDependencia: entidad.perfilId };
    } else if (campoDependencia === CampoColaborador.SEDE) {
      return { ...campo, campoDependencia: CampoColaborador.SEDE, idDependencia: entidad.sedeId };
    } else if (campoDependencia === CampoColaborador.SUBSEDE) {
      return { ...campo, campoDependencia: CampoColaborador.SUBSEDE, idDependencia: entidad.subsedeId };
    } else if (campoDependencia === CampoColaborador.SECCION) {
      return { ...campo, campoDependencia: CampoColaborador.SECCION, idDependencia: entidad.seccionId };
    } else if (campoDependencia === CampoColaborador.CARGO) {
      return { ...campo, campoDependencia: CampoColaborador.CARGO, idDependencia: entidad.cargoId };
    } else if (campoDependencia === CampoColaborador.PLANILLA) {
      return { ...campo, campoDependencia: CampoColaborador.PLANILLA, idDependencia: entidad.tipoPlanillaId };
    }
    return campo;
  };


  const getListaCarpetasFiltro = async (empresaSeleccionada) => {
    // const _carpetas = await CarpetaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
    // setCarpetas(_carpetas);
    // if (!empresaSeleccionada?.filtroCarpeta?.length > 0) {
    //   return _carpetas;
    // }

    // var temp = [];
    // const _temporal = empresaSeleccionada?.filtroCarpeta?.map(e => {
    //   temp.push(_carpetas.find(c => c.id == e.id))
    // })

    // return temp;
  };

  const onClickBtnFiltrar = () => {
    setVisibleModalFiltrar(true);
  };

  const onClickDescargarExcel = async () => {
    setLoadingReporteExcel(true);
    const datos = cargarDatosExportar();
    try {
      const respuesta = await DetalleEnvioService.descargarExcelConsultaDocumento(datos);
      setLoadingReporteExcel(false);
      const filename = respuesta.headers.filename;
      saveAs(respuesta.data, filename);
    } catch {
      setLoadingReporteExcel(false);
      notification.error({
        message: "Error al intentar exportar los resultados. No se encontraron resultados."
      })
    }

  };

  const onClickDescargarPDF = async () => {
    setLoadingReportePdf(true);
    const datos = cargarDatosExportar();
    try {
      const respuesta = await DetalleEnvioService.descargarPDFConsultaDocumento(datos);
      setLoadingReportePdf(false);
      //history.push('/listar-reportes');
      const filename = respuesta.headers.filename;
      saveAs(respuesta.data, filename);
    } catch {
      setLoadingReportePdf(false);
      notification.error({
        message: "Error al intentar exportar los resultados. No se encontraron resultados."
      })
    }
  };

  const cargarDatosExportar = () => {
    const formPDF = {
      solicitanteId: getUsuarioAutenticado().id,
      organizacionId: getUsuarioAutenticado().organizacionId,
      empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
      login: getUsuarioAutenticado().numeroDocumentoIdentidad,//login
      origen:"PORTAL_COLABORADOR",
      colaborador: filtrosSeleccionados?.colaborador == undefined ? null : filtrosSeleccionados.colaborador,
      categoria: filtrosSeleccionados?.categoria == undefined ? null : filtrosSeleccionados.categoria,
      documentoIdentidad:
        filtrosSeleccionados?.documentoIdentidad == undefined ? null : filtrosSeleccionados.documentoIdentidad,
      tipoDocumento: filtrosSeleccionados?.tipoDocumento == undefined ? null : filtrosSeleccionados.tipoDocumento,
      cargo: filtrosSeleccionados?.cargo == undefined ? null : filtrosSeleccionados.cargo,
      periodoDocumento:
        filtrosSeleccionados?.periodoDocumento == undefined
          ? null
          : filtrosSeleccionados.periodoDocumento.format("MM/YYYY"),
      carpeta: filtrosSeleccionados?.carpeta == undefined ? null : filtrosSeleccionados.carpeta,
      estadoDocumento: filtrosSeleccionados?.estadoDocumento == undefined ? null : filtrosSeleccionados.estadoDocumento,
      sede: filtrosSeleccionados?.sede == undefined ? null : filtrosSeleccionados.sede,
      fechaEnvioInicio:
        filtrosSeleccionados?.fechaEnvio == null ? null : filtrosSeleccionados.fechaEnvio[0].format("DD/MM/YYYY"),
      fechaEnvioFin:
        filtrosSeleccionados?.fechaEnvio == null ? null : filtrosSeleccionados.fechaEnvio[1].format("DD/MM/YYYY"),
      subSede: filtrosSeleccionados?.subSede == undefined ? null : filtrosSeleccionados.subSede,
      perfil: filtrosSeleccionados?.perfil == undefined ? null : filtrosSeleccionados.perfil,
      seccion: filtrosSeleccionados?.seccion == undefined ? null : filtrosSeleccionados.seccion,
      tipoPlanilla: filtrosSeleccionados?.tipoPlanilla == undefined ? null : filtrosSeleccionados.tipoPlanilla,
      codigoColaborador: filtrosSeleccionados?.codigoColaborador == undefined ? null : filtrosSeleccionados.codigoColaborador,
      fechaIngresoInicio:
        filtrosSeleccionados?.fechaIngreso == null ? null : filtrosSeleccionados.fechaIngreso[0].format("DD/MM/YYYY"),
      fechaIngresoFin:
        filtrosSeleccionados?.fechaIngreso == null ? null : filtrosSeleccionados.fechaIngreso[1].format("DD/MM/YYYY"),
      fechaFirmaInicio:
        filtrosSeleccionados?.fechaFirma == null ? null : filtrosSeleccionados.fechaFirma[0],
      fechaFirmaFin:
        filtrosSeleccionados?.fechaFirma == null ? null : filtrosSeleccionados.fechaFirma[1],
    };
    return formPDF;
  };

  const BotonesExtra = () => {
    return (
      <React.Fragment>
        <Space>
          {" "}
          <Button onClick={onClickBtnFiltrar}>Filtrar</Button>{" "}
          <Button type="primary" loading={loadingReporteExcel} onClick={onClickDescargarExcel}>
            Exportar resultados a Excel
          </Button>{" "}
          <Button type="primary" loading={loadingReportePdf} onClick={onClickDescargarPDF}>
            Exportar resultados a PDF
          </Button>{" "}
        </Space>
      </React.Fragment>
    );
  };

  return (
    <Container icono={<UnorderedListOutlined />} titulo="Consulta Documento" botonExtra={<BotonesExtra />}>
      <TagFiltros />
      <TablaDocumentos onChangePagination={onChangePagination} />
      <ModalFiltrar />
      {/* <ModalHistorialNotificacion /> */}
      <ModalActualizarCorreo />
      <ModalAsignarCarpeta />
      <ModalVisorPdf />
      <ModalSubirDocumento />
    </Container>
  );
};

export default ConsultaDocumentoPage;
